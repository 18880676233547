import { createStore, useStoreState } from 'react-thermals';
import { defineTheme } from './defineTheme.js';

function setModePreference(mode) {
  localStorage.setItem('ThemeMode', mode);
}

function getModePreference() {
  const savedMode = localStorage.getItem('ThemeMode');
  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  const userAgentMode = mediaQuery.matches ? 'dark' : 'light';
  return savedMode || userAgentMode;
}

const state = {
  themes: {
    dark: defineTheme('dark'),
    light: defineTheme('light'),
  },
  mode: getModePreference(),
};
state.currentTheme = state.themes[state.mode];

const actions = {
  toggleDarkMode,
};

const store = createStore({ state, actions });

export function toggleDarkMode() {
  store.setState(old => {
    const newMode = old.mode === 'dark' ? 'light' : 'dark';
    setModePreference(newMode);
    return {
      ...old,
      currentTheme: old.themes[newMode],
      mode: newMode,
    };
  });
}

export function useTheme() {
  return useStoreState(store);
}
