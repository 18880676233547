import { BrowserRouter } from 'react-router-dom';
import AllRoutes from './components/AllRoutes/AllRoutes.js';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useTheme } from './stores/ThemeStore/themeStore.js';

function App() {
  const { mode, currentTheme } = useTheme();
  return (
    <div className={`Component App theme theme-${mode}`}>
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <BrowserRouter>
          <AllRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
