import { lazy } from 'react';

const ZeroKnowledgeRoute = {
  path: '/zero',
  exact: true,
  component: lazy(() => import('./ZeroKnowledgePage.js')),
  permission: [],
  description: 'About zero-knowledge encryption',
};

export default ZeroKnowledgeRoute;
