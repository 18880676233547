import { createTheme } from '@mui/material';

export function defineTheme(mode) {
  return createTheme({
    palette: {
      mode,
      type: mode,
      primary: {
        main: '#bf360c',
      },
      secondary: {
        main: '#01579b',
      },
      background: {
        default: mode === 'dark' ? '#202020' : '#fff',
        paper: mode === 'dark' ? '#2e2e2e' : undefined,
      },
    },
    typography: {
      fontFamily: 'Assistant',
      h1: {
        fontFamily: 'Akshar',
      },
      h2: {
        fontFamily: 'Akshar',
      },
      h3: {
        fontFamily: 'Akshar',
      },
      h4: {
        fontFamily: 'Akshar',
      },
      h5: {
        fontFamily: 'Akshar',
      },
      h6: {
        fontFamily: 'Akshar',
      },
    },
  });
}
