import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from '../../pages/routes.js';
import Loader from '../Loader/Loader.js';

export default function AllRoutes() {
  const lazyRoutes = routes.map(route => {
    return {
      path: route.path,
      element: <route.component />,
    };
  });

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {lazyRoutes.map(route => (
          <Route key={route.path} {...route} />
        ))}
      </Routes>
    </Suspense>
  );
}

AllRoutes.propTypes = {};
