import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Loader.css';
import { useTimeoutWhen } from 'rooks';
import { CircularProgress } from '@mui/material';

/**
 * &lt;Loader /&gt; is... TODO: explain this component in markdown for Storybook
 */
export default function Loader({
  delay = 250,
  label = '',
  className = '',
  ...moreProps
}) {
  const [show, setShow] = useState(false);
  useTimeoutWhen(() => setShow(true), delay);

  if (!show) {
    return '';
  }
  return (
    <div className={`Component Loader ${className}`}>
      <CircularProgress {...moreProps} />
      {label && <span className="label">{label}</span>}
    </div>
  );
}

Loader.propTypes = {
  /** Delay in milliseconds before showing */
  delay: PropTypes.string,
  /** Diameter of the circle animation */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Text next to loader */
  label: PropTypes.node,
  /** An additional className to apply to this component */
  className: PropTypes.string,
};
