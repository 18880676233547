import ReadNoteRoute from './ReadNote/ReadNoteRoute.js';
import HomeRoute from './Home/HomeRoute.js';
import Http404Route from './Http404/Http404Route.js';
import PrivacyPolicyRoute from './PrivacyPolicy/PrivacyPolicyRoute.js';
import LegalRoute from './Legal/LegalRoute.js';
import ZeroKnowledgeRoute from './ZeroKnowledge/ZeroKnowledgeRoute.js';

const routes = [
  ReadNoteRoute,
  LegalRoute,
  PrivacyPolicyRoute,
  ZeroKnowledgeRoute,
  HomeRoute,
  // catch-all route must come last
  Http404Route,
];

export default routes;
