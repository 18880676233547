import { lazy } from 'react';

const PrivacyPolicyRoute = {
  path: '/privacy',
  exact: true,
  component: lazy(() => import('./PrivacyPolicyPage.js')),
  permission: [],
  description: 'The legal privacy policy',
};

export default PrivacyPolicyRoute;
