import { lazy } from 'react';

const LegalRoute = {
  path: '/legal',
  exact: true,
  component: lazy(() => import('./LegalPage.js')),
  permission: [],
  description: 'The legal terms and conditions',
};

export default LegalRoute;
